import { Injectable, OnDestroy, inject } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';

@Injectable()
export class TranslatePaginatorIntl
  extends MatPaginatorIntl
  implements OnDestroy
{
  private translocoService = inject(TranslocoService);

  subscriptions: Subscription;
  translateObject$ =
    this.translocoService.selectTranslateObject('matPagninatorKeys');

  constructor() {
    super();
    this.subscriptions = this.translateObject$.subscribe((translate) => {
      this.itemsPerPageLabel = translate.itemsPerPageKey;
      this.nextPageLabel = translate.nextPageKey;
      this.previousPageLabel = translate.previousPageKey;
      this.firstPageLabel = translate.firstPageKey;
      this.lastPageLabel = translate.lastPageKey;
    });
    this.getRangeLabel = this.translateRangeLabel;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  translateRangeLabel(page: number, pageSize: number, length: number): string {
    const of = this.translocoService.translate('matPagninatorKeys.ofKey');
    if (length === 0 || pageSize === 0) {
      return '0 ' + of + ' ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
  }
}
